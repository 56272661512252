import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ApiService } from '../Services/apiservices';
import DataContext from '../Elements/context';
import { useNavigate } from 'react-router-dom';
function ScenerioModal({ propertyId }) {

    const navigate = useNavigate()
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const [isLoader, setIsLoader] = useState(true);
    const [scenerioList, setScenerioList] = useState([]);

    const getScenerioListData = useCallback(async () => {
        try {
            let dataString = {
                property_id: propertyId
            }
            const res = await ApiService.postData('/property/getsceneriolist', dataString);
            if (res.status === 'success') {
                setScenerioList(res.sceneroList)
            } else if (res.status === 'error' && (res.message === 'session_expired' || res.message === 'Account Inactive')) {
                localStorage.removeItem('USER_TOKEN');
                navigate('/');
            }
        } catch (error) {
            console.error('Error fetching available slot date-time data:', error);
        } finally {
            setIsLoader(false);
        }
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem("USER_TOKEN")) {
                getScenerioListData();
            }
        }
        didMountRef.current = false;
    }, []);

    const scenerioModal = () => {
        contextValues.setToggleScenerio(!contextValues.toggleScenerio)
    }

    return (<>
        <Modal show={contextValues.toggleScenerio} onHide={(e) => scenerioModal()} backdrop="static" size='xl' >
            <div className="loginContant" style={{padding:'20px'}}>
            <button type="button" class="modal-close" onClick={() => { scenerioModal() }}><i class="ri-close-line"></i></button>
                <div className='container'>
                    <div className="row g-3">
                        <h5 >Property Scenerios</h5>

                        <div className="table-responsive">
                            {scenerioList.length > 0 ?

                                <table className="table table-striped table-bordered text-center">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Inflation</th>
                                            <th>Rental Growth</th>
                                            <th>Growth Assumption</th>
                                            <th>Date Published</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scenerioList.length > 0 &&
                                            scenerioList.map((item, key) => {
                                                const date1 = new Date(item.created_at).toDateString();
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {(item.inflation)?.toFixed(3)}
                                                        </td>
                                                        <td>{(item.rentalgrowth)?.toFixed(3)}</td>
                                                        <td>{(item.growthassumpt)?.toFixed(3)}</td>
                                                        <td>{date1}</td>

                                                        <td>
                                                            <div className="d-flex justify-content-center gap-2">
                                                                <a href={`/scenerio/${item?._id}`} className="btn btn-sm btn-outline-primary">
                                                                    <i class="ri-pencil-fill"></i>
                                                                </a>


                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                : <p className="text-center">No Scenerio Found</p>}
                        </div>
                    </div></div>

            </div>

        </Modal>
    </>)
}

export default ScenerioModal