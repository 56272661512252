
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useNavigate } from "react-router-dom";
import Accountsidebar from "./account_sidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import { ApiService } from "../../component/Services/apiservices";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import DataContext from "../../component/Elements/context";
import ScenerioModal from "../../component/modals/scenerio_modal";

const MyProperty = () => {
  const contextValues = useContext(DataContext)
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const [myproperties, setmyproperties] = useState([])
  const [deleteId, setdeleteId] = useState({});
  const [propertyId, setpropertyId] = useState('');
  const [property_img_url, setproperty_img_url] = useState(null);
  const [hasMore, setHasmore] = useState(false)

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getMyPropertyData()
    }
    didMountRef.current = false;
  }, [])
  const getMyPropertyData = (type) => {
    let pageNumber = 1
    if (type == 'delete') {
      pageNumber = 1
      setmyproperties([])
    }
    else {
      pageNumber = Math.ceil(myproperties.length / 10) + 1;
    }

    ApiService.fetchData(`/getpropertylist?page=${pageNumber}`)
      .then((res) => {
        if (res.status === 'success') {
          const { propertyList, currentPage, totalPages } = res;

          setmyproperties((prevProperties) => [
            ...prevProperties,
            ...propertyList,
          ]);
          setHasmore(currentPage < totalPages);
          setproperty_img_url(res.property_img_url);
        } else if (res.status === 'error' && (res.message === 'session_expired' || res.message === 'Account Inactive')) {
          localStorage.removeItem('USER_TOKEN');
          navigate('/');
        }

      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
      });
  };
  const deleteproperty = (itemid) => {
    const dataString = {
      purchase_id: deleteId._id,
      property_id: deleteId.property_data._id
    }


    ApiService.postData('/deleteproperty', dataString).then((res) => {
      if (res?.status == "success") {
        toast.success(res.message)
        setTimeout(() => {
          setShow(false)
          getMyPropertyData('delete')
          setHasmore(true)
        }, 500)
      } else if (res?.status == "error" && res.message == 'session_expired') {
        toast.error(res?.data?.message)
        localStorage.removeItem("USER_TOKEN")
        navigate('/')
      }
      else {
        setShow(false)
        toast.error(res?.message)
      }
    }).catch((error) => { setShow(false) })

  }
  const onDeleteProp = (item) => {
    setdeleteId(item)
    setShow(!show)
  }
  const scenerioModal = (id) => {
    setpropertyId(id)
    contextValues.setToggleScenerio(!contextValues.toggleScenerio)
  }
  return (<>
    <Header></Header>
    <section className="section-gap-md">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Accountsidebar />
          </div>
          <div className="col-lg-9">
            <div className="ccpanel">
              <div className="ccpanel-header d-flex justify-content-between">
                <h5 className="mb-0">My Properties</h5>
                <button
                  className="btn btn-primary btn-md"
                  type="button"
                  name="submit"
                  onClick={(e) => {
                    navigate('/')
                  }}

                >
                  Add Property
                </button>
              </div>
              <div className="ccpanel-body">
                <div className="row g-3">
                  <div className="table-responsive">
                    {myproperties.length > 0 ?
                      <InfiniteScroll
                        dataLength={myproperties.length}
                        next={getMyPropertyData}
                        hasMore={hasMore}
                        loader={<h6>Loading...</h6>}
                        style={{ overflow: "hidden !important" }}
                      >
                        <table className="table table-striped table-bordered text-center">
                          <thead className="thead-dark">
                            <tr>
                              <th>Title</th>
                              <th>Equity</th>
                              <th>Net Annual Cashflow</th>
                              <th>Date Published</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {myproperties.length > 0 &&
                              myproperties.map((item, key) => {
                                const date1 = new Date(item.property_data.created_at).toDateString();
                                return (
                                  <tr key={key}>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-start" >
                                        <img
                                          src={item?.property_data?.property_image ? property_img_url + item?.property_data?.property_image : '/img/logo.png'}
                                          alt="Property"
                                          className="img-thumbnail me-3"
                                          style={{ width: "80px", height: "auto" }}
                                        />
                                        <div>
                                          <a
                                            href={`/maintainance/${item?.property_data?._id}`}
                                            className="fw-bold text-decoration-none"
                                          >
                                            {item?.property_data?.property_name}
                                          </a>
                                          <div className="text-muted small">{item?.property_data?.property_adress}</div>

                                        </div>
                                      </div>
                                    </td>
                                    <td>{(item.maintenance_data.equity)?.toFixed(3)}</td>
                                    <td>{(item.maintenance_data.net_annual_cash_flow)?.toFixed(3)}</td>
                                    <td>{date1}</td>

                                    <td>
                                      <div className="d-flex justify-content-center gap-2">
                                        <a href={`/maintainance/${item?.property_data?._id}`} className="btn btn-sm btn-outline-primary">
                                          <i class="ri-pencil-fill"></i>
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-sm btn-outline-danger remove-file" onClick={() => { onDeleteProp(item) }}><i class="ri-delete-bin-fill"></i>
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-sm btn-outline-success remove-file" onClick={() => { navigate(`/createscenerio/${item?.property_data?._id}`) }}>WhatIf
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-sm btn-outline-warning remove-file" onClick={() => { scenerioModal(item?.property_data?._id) }} >Scenerios
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </InfiniteScroll> : <p className="text-center">No Property Found</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SweetAlert
      warning
      confirmBtnCssClass="alertpop"
      title={` Are you sure you want to delete this property ? `}
      confirmBtnText="Confirm"
      cancelBtnText="Cancel"
      show={show}
      onConfirm={deleteproperty}
      onCancel={onDeleteProp}
      btnSize="md"
      showCancel
      cancelBtnBsStyle="danger"
    ></SweetAlert>
    {contextValues.toggleScenerio && <ScenerioModal propertyId={propertyId}></ScenerioModal>}

    <Footer></Footer>

  </>)
}

export default MyProperty