import SweetAlert from "react-bootstrap-sweetalert";
import React, { useContext, useState, useEffect, useRef } from "react";
import Header from "../../component/Header/header";
import Footer from "../../component/Footer/footer";
import { useNavigate } from "react-router-dom";
import DataContext from "../../component/Elements/context";
import Accountsidebar from "./account_sidebar";
import { ApiService } from "../../component/Services/apiservices";



const Account = () => {
  const contextValues = useContext(DataContext)
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
   

    }
    didMountRef.current = false;
  }, [])


  return (<>
    <Header></Header>
    <section className="section-gap-md">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Accountsidebar />
          </div>
          <div className="col-lg-9">
                <div className="ccpanel">
                  <div className="ccpanel-header">
                    <h5 className="mb-0">Account Overview</h5>
                  </div>
                  <div className="ccpanel-body">
                    <div className="row g-3">
                    <div className="col-lg-6">
                        <a href="/dashboard" className="aobox">
                          <img src="/img/chart.png" className="mb-3" alt='pin' />
                          <h6 className="mb-0">Dashboard</h6>
                          <p className="mb-0 tx-12"> Everything you need to manage your experience</p>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <a href="/my-profile" className="aobox">
                          <img src="/img/user.png" className="mb-10" alt='user' />
                          <h6 className="mb-0">My Profile</h6>
                          <p className="mb-0 tx-12">Manage your account information</p>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <a href="/my-property" className="aobox">
                          <img src="/img/home.png" className="mb-3" alt='pin' />
                          <h6 className="mb-0">My Properties</h6>
                          <p className="mb-0 tx-12">Your go-to hub for managing property information.</p>
                        </a>
                      </div>
                      <div className="col-lg-6">
                        <a href="/help-support" className="aobox">
                          <img
                            src="/img/helpsupport.png"
                            className="mb-3"
                            alt='support'
                          />
                          <h6 className="mb-0">Help & Support</h6>
                          <p className="mb-0 tx-12">Help regarding recent purchase & other</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </section>

    <Footer></Footer>

  </>)
}

export default Account