
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import Home from './container/Home';
import PropertyAddress from './container/Property-Purchase-Forms/property-address';
import Purchasedetail from './container/Property-Purchase-Forms/purchase-detail';
import { DataProvider } from './container/Home/DataContext';
import PurchaseSummary from './container/Property-Purchase-Forms/purchase-summary';
import MaintainnceTable from './container/Property-Purchase-Forms/maintainence-table';
import Graphs from './container/Property-Purchase-Forms/graphs';
import Account from './container/MyAccount/account';
import NewsBlogs from './container/NewsBlogs/newsblogs';
import MyProperty from './container/MyAccount/properties';
import { ToastContainer } from 'react-toastify';
import Pages from './container/PagesSection';
import BlogDetail from './container/BlogDetail';
import Contactus from './container/ContactUs';
import HelpSupport from './container/Help-Support';
import MyProfile from './container/MyAccount/profile';
import Dashboard from './container/MyAccount/dashboard';
import ChangeScenerio from './container/Property-Purchase-Forms/change-scenerio';
import ScenerioDetail from './container/Property-Purchase-Forms/scenerio-detail'
import PageNotFound from './container/PagesSection/page_not_found';
function App() {
  return (
    <div className="App">
       <ToastContainer />
     <Router>
<DataProvider>
<Routes>
   <Route exact path='/' activeClassName="active" element={ <Home /> }/>
    <Route exact path='/property-address' activeClassName="active" element={ <PropertyAddress /> }/>
    <Route exact path='/:slug' activeClassName="active" element={ <Pages /> }/>
    <Route exact path='/purchase-graphs/:id' activeClassName="active" element={ <Graphs /> }/>
    <Route exact path='/purchase-details/:id' activeClassName="active" element={ <Purchasedetail /> }/>
    <Route exact path='/my-account' activeClassName="active" element={ <Account /> }/>
    <Route exact path='/dashboard' activeClassName="active" element={ <Dashboard /> }/>
    <Route exact path='/news-blogs' activeClassName="active" element={ <NewsBlogs /> }/>
    <Route exact path='/contact-us' activeClassName="active" element={ <Contactus /> }/>
    <Route exact path='/help-support' activeClassName="active" element={ <HelpSupport /> }/>
    <Route exact path='/blog-detail/:slug' activeClassName="active" element={ <BlogDetail /> }/>
    <Route exact path='/my-property' activeClassName="active" element={ <MyProperty /> }/>
    <Route exact path='/my-profile' activeClassName="active" element={ <MyProfile /> }/>
    <Route exact path='/error_404' activeClassName="active" element={ <PageNotFound /> }/>
    <Route exact path='/purchase-summary/:id' activeClassName="active" element={ <PurchaseSummary /> }/>
    <Route exact path='/createscenerio/:id' activeClassName="active" element={ <ChangeScenerio /> }/>
    <Route exact path='/scenerio/:id' activeClassName="active" element={ <ScenerioDetail /> }/>
    <Route exact path='/maintainance/:id' activeClassName="active" element={ <MaintainnceTable /> }/>
    </Routes>
    </DataProvider>
    </Router>
    </div>
  );
}

export default App;
