import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer/footer"
import Header from "../../component/Header/header"
import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../component/Services/apiservices";
import Alert from 'react-bootstrap/Alert';

const Contactus = () => {
    const navigate = useNavigate()
    const didMountRef = useRef(true);
    const [contactInputs, setcontactInputs] = useState({
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
        contact_subject: "",
        contact_message: "",
    });
    const [errorMessage, seterrorMessage] = useState(false)
    const [successMessage, setsuccessMessage] = useState(false)


    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    });

    const navigateback = (e) => {
        e.preventDefault()
        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };
    const handleContactInput = (e) => {
        seterrorMessage('')
        const { name, value } = e.target;
        setcontactInputs({
            ...contactInputs,
            [name]: value,
        });
    };

    const ContactformHandle = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            if (contactInputs?.contact_name == "") {
                seterrorMessage('Please Enter Your Name')
                return;
            }
            if (contactInputs?.contact_email == "") {
                seterrorMessage('Please Enter Your Email Address')
                return;
            }
            const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
            if (!validEmail.test(contactInputs?.contact_email)) {
                seterrorMessage('Please Enter Valid Email Address');
                return;
            }
            if (contactInputs?.contact_mobile == "") {
                seterrorMessage('Please Enter Your Mobile Number');
                return;
            }

            if (contactInputs?.contact_message == "") {
                seterrorMessage('Please Enter Your Message');
                return;
            }
            ApiService.postData("/contact-us-process", contactInputs).then(
                (res) => {
                    if (res?.status == "success") {
                        setsuccessMessage(res?.message)
                        setcontactInputs({ contact_name: " ", contact_email: " ", contact_mobile: " ", contact_subject: " ", contact_message: " ", })
                        setTimeout(() => {
                            setsuccessMessage('')
                        }, 500);
                    } else if (res?.status == "error") {
                        seterrorMessage(res?.message);
                        setTimeout(() => {
                            seterrorMessage('')
                        }, 500);
                    }
                }
            ).catch(()=>{
                seterrorMessage('')
            });
        }
    };
    return (<>
        <Header></Header>
        <section className="section-gap-md">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="section-title">
                            <h2>Get In Touch With Us</h2>
                            <p>If you have any questions or enquiries please feel free to contact us alternatively you can complete our online enquiry form located below and we will get back to you as soon as possible.</p>
                        </div>
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <div className="form-group"><label>Your Name</label><input type="text" name="contact_name" className="form-control required" placeholder="Name" value={contactInputs.contact_name} onChange={(e) => { handleContactInput(e) }} /></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group"><label>Email Address</label><input type="email" name="contact_email" className="form-control required" placeholder="Email" value={contactInputs.contact_email} onChange={(e) => { handleContactInput(e) }} /></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group"><label>Mobile Number</label><input type="number" name="contact_mobile" className="form-control required" placeholder="Mobile Number" value={contactInputs.contact_mobile} onChange={(e) => { handleContactInput(e) }} /></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group"><label>Subject</label><input type="text" name="contact_subject" className="form-control required" placeholder="Subject" value={contactInputs.contact_subject} onChange={(e) => { handleContactInput(e) }} /></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group"><label>Message</label><textarea name="contact_message" className="form-control required" value={contactInputs.contact_message} onChange={(e) => { handleContactInput(e) }}> </textarea></div>
                            </div>
                            <div className="col-lg-6"><button type="button" className="btn-md btn btn-primary mb-5" onClick={(e) => { ContactformHandle(e) }} >Submit</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>

    </>)
}

export default Contactus