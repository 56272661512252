import React, { createContext, useEffect, useState, useRef } from 'react';
import {ApiService} from '../Services/apiservices'

const DataContext = createContext();
export const DataProvider = ({ children }) => {
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
  const [settingData, setSettingData] = useState([]);
  const [rowUserData, setRowUserData] = useState({});
  const [UserToken, setUserToken] = useState(localStorage.getItem('USER_TOKEN'));
  const [toggleScenerio, setToggleScenerio] = useState(false);
  const [toggleChnageScenerio, setToggleChangeScenerio] = useState(false);
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
     
      getSettingData()

    }
    didMountRef.current = false;
  }, [])
  const getSettingData = () => {
    try {
      ApiService.fetchData('admin/getadminsettingdata').then((res) => {
        if (res.status === 'success') {
          setSettingData(res.data)
          setSettingImageBaseUrl(res.setting_img_path)
        }
      })
    } catch (error) {
      console.error('An error occurred while fetching blog data:', error);
    }
  }
 
  return (
    <DataContext.Provider value={
      {
        settingData, setSettingData,
        settingImageBaseUrl, setSettingImageBaseUrl,
        UserToken, setUserToken,
        rowUserData,setRowUserData,
        toggleScenerio,setToggleScenerio,
        toggleChnageScenerio,setToggleChangeScenerio
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;