import React, { useState, useEffect, useRef } from "react";
import Loginmodal from "../modals/login_modal";
import { useNavigate } from "react-router-dom";
const Header = () => {
    let getusertoken = localStorage.getItem("USER_TOKEN")
    const [showlogin, setshowlogin] = useState(false)
    const [type, settype] = useState('')
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const handleClose = () => {
        setshowlogin(false)
    }
    const handlelogin = () => {
        if (getusertoken == null) {
            setshowlogin(true)
        }
        else{
           
        }
      }
    return (<>
        <header className="header">
            <div className="container">
                <div className="header-left">
                    <a href="/" className="logo"><img src="/img/logo.png" width={175} height={31}></img></a>
                </div>
                { getusertoken ==null?<>
                    <div className="header-right justify-content-end">
                    <button type="button" className="btn" onClick={()=>{ settype('signin');handlelogin() }}>Sign In</button>
                    <button type="button" className="btn btn-primary" onClick={()=>{ settype('join');handlelogin() }}>Join</button>
                </div>
                </>:<>
                {getusertoken !==null 
                ?<>
                 <div className="header-right justify-content-end" onClick={()=>{ navigate('/dashboard')}}>
                    <div>
                    <img class="rounded-circle header-profile-user" src="/img/avatar-1.jpg" alt="Header Avatar" style={{width:'35px'}}/>
                    </div>
                </div>
                </>:''
                }
               
                </>}
                
                {showlogin && <Loginmodal showlogin={showlogin} handleClose={handleClose} setshowlogin={setshowlogin} type={type}></Loginmodal>}
            </div>
        </header>
    </>)
}

export default Header