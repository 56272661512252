
import React, { useState, useRef, useEffect, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiService } from "../../component/Services/apiservices";
import { Alert } from "react-bootstrap";
import DataContext from "../Elements/context";
const Footer = () => {
  let apiServices = new ApiService();
  const contextValues= useContext(DataContext)
  const [errormsg, setErrorMessage] = useState("");
  const [successmsg, setSuccessMessage] = useState("");
  const [footerData, setFooterData] = useState({});

  const [newsletteremail, setnewslettermail] = useState("");
  const Newsletter = (e) => {
    setErrorMessage("");
    if (newsletteremail == "" || newsletteremail == null) {
      setErrorMessage('Please enter email address')
      return;
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletteremail)) {
      const email = newsletteremail.toLowerCase();
      let datastring = { newsletter_email: email };
      apiServices.getnewsletterPostRequest(datastring).then((res) => {
        if (res?.data?.status == "success") {
          setSuccessMessage(res?.data?.message)
          setTimeout(() => {
            setnewslettermail("");
            setSuccessMessage('')
          }, 2000)

        } else {
          setErrorMessage(res?.data?.message)
          setTimeout(() => {
            setnewslettermail("");
            setErrorMessage('')
          }, 2000)
        }
      });
    } else {
      setErrorMessage("Invalid email address. Please enter valid email")
      return;
    }
  };
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
   
      getFooterData()
    }
    didMountRef.current = false;
    
  })

  const getFooterData = () => {
    apiServices.getfooterdatarequest().then(res => {
        if (res?.data?.status === "success") {
            setFooterData(res.data.FooterData);
           
        }
    }).catch((error) => { });
};

const admindata= contextValues.settingData


  return (<>
    <ToastContainer autoClose={2000} />
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <a href="/" className="footer-logo mb-20">
              <img src="/img/logo.png" width={150} height={27}></img>
            </a>
            <p>{admindata?.admin_aboutus}</p>
            {admindata?.admin_facebook_url || admindata?.admin_twitter_url || admindata?.admin_instagram_url || admindata?.admin_linkedin_url || admindata?.admin_youtube_url || admindata?.pinterest_url ? <>
              <h6 className="footer-title mb-15">Follow Us On</h6>
         
                <ul className="footer-social">
                  {admindata?.admin_facebook_url ? <li>
                    <a href={admindata?.admin_facebook_url}> <i class="ri-facebook-circle-fill"></i></a>
                  </li> : ''}
                  {admindata?.admin_twitter_url ? <li>
                    <a href={admindata?.admin_twitter_url}> <i class="ri-twitter-fill"></i></a>
                  </li> : ''}
                  {admindata?.admin_instagram_url ? <li>
                    <a href={admindata?.admin_instagram_url}> <i class="ri-instagram-fill"></i></a>
                  </li> : ''}
                  {admindata?.admin_linkedin_url ? <li>
                    <a href={admindata?.admin_linkedin_url}> <i class="ri-linkedin-box-fill"></i></a>
                  </li> : ''}
                  {admindata?.admin_youtube_url ? <li>
                    <a href={admindata?.admin_youtube_url}><i class="ri-youtube-fill"></i></a>
                  </li> : ''}
                  {admindata?.pinterest_url ? <li>
                    <a href={admindata?.pinterest_url}><i class="ri-pinterest-fill"></i></a>
                  </li> : ''}

                </ul>
           
            </> : ''}

          </div>
          {footerData && footerData.footer1_descrip ?<>
            <div className="col-lg-2" dangerouslySetInnerHTML={{ __html: footerData.footer1_descrip }}> 
            </div>
          
          </>:''}
          {footerData && footerData.footer2_descrip ?<>
            <div className="col-lg-2" dangerouslySetInnerHTML={{ __html: footerData.footer2_descrip }}> 
            </div>
          
          </>:''}
         
       

          <div className="col-lg-4">
            <h6 className="footer-title mb-15">Keep Yourself Up to Date</h6>
            <p>Enter your email address to register to our newsletter subscription</p>
            {errormsg ? <Alert key={'danger'} className='danger' variant='danger'>
              {errormsg}
            </Alert> : ''}
            {successmsg ? <Alert key={'success'} className='success' variant='success'>
              {successmsg}
            </Alert> : ''}
            <div className="newsletter mb-40 d-flex">

              <input placeholder="Enter Your Email Address" value={newsletteremail} name="newsletter_email" onChange={(e) => {
                setnewslettermail(e.target.value);
                setErrorMessage("");
                setSuccessMessage('')
              }}></input>
              <button type="button" className="btn btn-primary newsletterbtn" onClick={Newsletter}>Subscribe</button>

            </div>

          </div>
        </div>
      </div>
    </footer>
    <div className="copyright text-center">
      Copyright 2023 Proptrail. All Rights Reserved
    </div>
  </>)
}

export default Footer