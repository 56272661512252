import Footer from "../../component/Footer/footer"
import Header from "../../component/Header/header"
import Accountsidebar from "../MyAccount/account_sidebar"
import DataContext from "../../component/Elements/context"
import { useContext } from "react"

const HelpSupport = () => {
    const contextValues = useContext(DataContext)
    return (<>
        <Header></Header>
        <section className="section-gap-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <Accountsidebar />
                    </div>
                    <div className="col-lg-9">
                        <div className="acpanel">
                            <div className="acpanel-header">
                                <h4>Help & Support</h4>
                            </div>
                            <div className="acpanel-body">
                                <div className="p-5 helpbox text-center">
                                    <img
                                        className="img-fluid mb-2"
                                        src="/img/help.webp"
                                        style={{ width: "200px" }}
                                    ></img>
                                    <h6>How can we help you today?</h6>
                                    <p className="tx-color-02 tx-13 mb-0">
                                        Our customer service team will be able to assist you
                                        with any order or query
                                    </p>
                                </div>
                                <div className="aclist">
                                    <ul>
                                        <li>
                                            <a
                                                href={`mailto:${contextValues.settingData.admin_administration_email}`}
                                                target="new"
                                            >
                                                <div className="aclist-icon">
                                                    <img src="/img/email.png"></img>
                                                </div>
                                                <div>
                                                    <p className="mb-0">Email Us</p>
                                                    <span className="tx-color-02 tx-11">
                                                        Write to Proptrail directly for any
                                                        query
                                                    </span>
                                                </div>
                                                <i className="d-icon-angle-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`tel:${contextValues.settingData.admin_mobile}`}
                                                target="new"
                                            >
                                                <div className="aclist-icon">
                                                    <img src="/img/hphone.png"></img>
                                                </div>
                                                <div>
                                                    <p className="mb-0">Call Us</p>
                                                    <span className="tx-color-02 tx-11">
                                                        Get in touch and we will be happy to help
                                                        you
                                                    </span>
                                                </div>
                                                <i className="d-icon-angle-right"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={`https://wa.me/${contextValues?.settingData?.admin_watsapp_no?.replace(/\s+/g, '')}`}
                                                target="new"
                                            >
                                                <div className="aclist-icon">
                                                    <img src="/img/whatsapp.png"></img>
                                                </div>
                                                <div>
                                                    <p className="mb-0">Whatsapp</p>
                                                    <span className="tx-color-02 tx-11">
                                                        Get in touch and we will be happy to help
                                                        you
                                                    </span>
                                                </div>
                                                <i className="d-icon-angle-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>

    </>)
}

export default HelpSupport